import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "placeholders",
      "style": {
        "position": "relative"
      }
    }}>{`Placeholders`}<a parentName="h1" {...{
        "href": "#placeholders",
        "aria-label": "placeholders permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Placeholders can be used to replace missing content or denote areas left intentionally blank.
    </LeadParagraph>
    <p>{`Examples of possible use cases for placeholders:`}</p>
    <ul>
      <li parentName="ul">{`In place of a missing image for example in a search listing blog posts, news items, or events.`}</li>
      <li parentName="ul">{`As a default image preview for a user uploaded image.`}</li>
    </ul>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Default HDS placeholders include `}<strong parentName="p">{`image placeholders`}</strong>{` and `}<strong parentName="p">{`user image placeholders`}</strong>{` for different sizes. `}</p>
    <ul>
      <li parentName="ul">{`Always use the image size closest to your needs. The size of the placeholder icon has been optimised to the image size.`}</li>
      <li parentName="ul">{`Do not scale images larger. If you need a size between available sizes, choose the next largest image file.`}</li>
      <li parentName="ul">{`You can crop the images to suit your aspect ratio.`}</li>
    </ul>
    <h3 {...{
      "id": "image-placeholders",
      "style": {
        "position": "relative"
      }
    }}>{`Image placeholders`}<a parentName="h3" {...{
        "href": "#image-placeholders",
        "aria-label": "image placeholders permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Different sizes of image placeholders are available. `}</p>
    <p>{`You can `}<ExternalLink href="/images/foundation/visual-assets/placeholders/HDS-placeholder-image.zip" mdxType="ExternalLink">{`download the image placeholder package (a .zip file)`}</ExternalLink>{`, or just specific image files by clicking images in the table below and saving them.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dimensions`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixel size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-s.png" alt="Open placeholder image Small" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 x 150 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 x 150 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image thumbnail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-s@2x.png" alt="Open placeholder image Small @2x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 x 150 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 x 400 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image thumbnail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-s@3x.png" alt="Open placeholder image Small @3x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 x 150 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 450 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image thumbnail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-m.png" alt="Open placeholder image Medium" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 x 200 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 x 200 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-m@2x.png" alt="Open placeholder image Medium @2x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 x 200 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 400 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-m@3x.png" alt="Open placeholder image Medium @3x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 x 200 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`900 x 600 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-l.png" alt="Open placeholder image Large" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 400 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 400 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-l@2x.png" alt="Open placeholder image Large @2x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 400 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1200 x 800 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-l@3x.png" alt="Open placeholder image Large @3x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600 x 400 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1800 x 1200 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-xl.png" alt="Open placeholder image Extra large" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Extra large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1200 x 800 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1200 x 800 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-xl@2x.png" alt="Open placeholder image Extra large @2x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Extra large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1200 x 800 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2400 x 1600 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/image-xl@3x.png" alt="Open placeholder image Extra large @3x scale" style={{
              "width": "100%",
              "maxWidth": "100px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Extra large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1200 x 800 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3600 x 2400 px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Image placeholders]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "user-image-placeholders",
      "style": {
        "position": "relative"
      }
    }}>{`User image placeholders`}<a parentName="h3" {...{
        "href": "#user-image-placeholders",
        "aria-label": "user image placeholders permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`User image placeholders are used only for replacing missing user profile images. `}</p>
    <p>You can <ExternalLink href="/images/foundation/visual-assets/placeholders/HDS-placeholder-user-image.zip" mdxType="ExternalLink">download the user image placeholder package (a .zip file)</ExternalLink>, or just specific image files by clicking images in the table below and saving them.</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dimensions`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixel size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-s.png" alt="Open placeholder user image Small" style={{
              "width": "100%",
              "maxWidth": "42px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42 x 42 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42 x 42 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-s@2x.png" alt="Open placeholder user image Small @2x scale" style={{
              "width": "100%",
              "maxWidth": "42px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42 x 42 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84 x 84 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-s@3x.png" alt="Open placeholder user image Small @3x scale" style={{
              "width": "100%",
              "maxWidth": "42px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Small`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42 x 42 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`126 x 126 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-m.png" alt="Open placeholder user image Medium" style={{
              "width": "100%",
              "maxWidth": "96px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96 x 96 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96 x 96 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-m@2x.png" alt="Open placeholder user image Medium @2x scale" style={{
              "width": "100%",
              "maxWidth": "96px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96 x 96 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`192 x 192 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-m@3x.png" alt="Open placeholder user image Medium @3x scale" style={{
              "width": "100%",
              "maxWidth": "96px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medium`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96 x 96 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288 x 288 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-l.png" alt="Open placeholder user image Large" style={{
              "width": "100%",
              "maxWidth": "128px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 x 128 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 x 128 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-l@2x.png" alt="Open placeholder user image Large @2x scale" style={{
              "width": "100%",
              "maxWidth": "128px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 x 128 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@2x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256 x 256 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/placeholders/user-image-l@3x.png" alt="Open placeholder user image Large @3x scale" style={{
              "width": "100%",
              "maxWidth": "128px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Large`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 x 128 dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@3x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`384 x 384 px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:User image placeholders]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      